body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body {
    background-color: antiquewhite;
}

.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.app-header {
    text-align: center;
    color: black;
    background-color: antiquewhite;
    border: 0.3vh solid #282c34;
    height: 7vh;
    width: 100vw;
    display: flex;
    position: fixed;
    top: 0;
    z-index: 10;
}

.app-header h1 {
    font-size: 4vh;
    margin: 1vh;
}

.menu {
    margin: 1.5vh 0;
}

.menu svg {
    padding: 0 1.5vh;
}

.menu.closed .flyout {
    display: none;
}

.menu.open .flyout {
    display: block;
    position: fixed;
    z-index: 20;
    margin-top: 1vh;
    padding: 1vh;
    background-color: antiquewhite;
    border: 0.1vh solid #282c34;
}

.menuItem {
    padding: 1vh;
}

.play-area {
    margin-top: 10vh;
    margin-bottom: 30vh;
    flex: 1;
    width: 100vw;
    display: flex;
    overflow: auto;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 1;
}

.controls {
    height: 10vh;
    width: 100vw;
    position: fixed;
    bottom: 20vh;
    border: 0.3vh solid #282c34;
    background-color: antiquewhite;
    z-index: 10;
}

.controls .round-control {
    padding: 1vh;
    min-width: 15vh;
    background-color: aliceblue;
    border-radius: 5px;
    border: none;
    position: absolute;
    left: 95vw;
    top: 50%;
    transform: translate(-100%, -50%);
    font-size: 3vh;
    color: #282c34;
}

.controls .info-text {
    padding: 1vh;
    min-width: 15vh;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    font-size: 4vh;
    color: #aa2c34;
}

.cards {
    background-color: burlywood;
    height: 20vh;
    width: 100vw;
    display: flex;
    overflow-x: auto;
    position: fixed;
    bottom:0;
    z-index: 10;
}

.card {
    border: 0.3vh solid #282c34;;
    border-radius: 5px;
    padding: 1vh;
    margin: 1vh;
    height: 15vh;
    width: 10vh;
    min-width: 10vh;
    position: relative;
}

.card {
    background-image: url("images/paper.jpg");
}

.card.hidden {
    background-color: burlywood;
}

.card.highlighted {
    border: 0.3vh solid #aa2c34;
}

.card .card-value {
    font-size: 10vh;
    color: #282c34;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.played-card {
    position: relative;
}

.played-card .player {
    padding: 1px;
    position: absolute;
    left: 85%;
    top: 17vh;
    margin-right: -15%;
    max-width: 10vh;
    overflow: hidden;
    transform: translate(-100%);
    display: block;
    background-color: aliceblue;
    color: #282c34;
    border: 0.1vh solid black;
    border-radius: 3px;
    font-size: calc(8px + 1vmin);
}

.played-card .delete {
    color: #e99;
    height: 22px;
    width: 22px;
    border-radius: 100%;
    position: absolute;
    left: 100%;
    top: 0;
    max-width: 10vh;
    transform: translate(-100%);
}

.card.starry-night.hidden {
    background-image: url("images/starry-night.jpg");
}

.ReactModalPortal {
    z-index: 100;
    position: relative;
}

.Modal {
    position: absolute;
    inset: 40px;
    border: 1px solid rgb(204, 204, 204);
    background-image: url("images/paper.jpg");
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
}

.Modal .input-name {
    margin: 2vh;
    min-width: 15vh;
}

.Modal .start-button {
    margin: 2vh;
    padding: 1vh;
    min-width: 15vh;
    background-color: aliceblue;
    border-radius: 5px;
    border: 1px solid #282c34;
    font-size: 3vh;
    color: #282c34;
}
.Modal .start-button:disabled {
    color: #a8aca4;
    border-color: #a8aca4;
}

.Modal .consent-all {
    margin: 1vh;
    padding: 0.3vh;
    background-color: #5555ff;
    color: #fff;
}

.Modal .consent-limited {
    margin: 1vh;
    padding: 0.3vh;
    background-color: #fff;
    color: #5555ff;
    border: none;
}

.Modal .impressum {
    position: absolute;
    bottom: 2vh;
    overflow: hidden;
    left: 2vh;
    text-decoration: underline;
}

.Datenschutz {
    margin: 10vh 2vh;
}

.show-session-link {
    position: absolute;
    inset: 40px;
    border: 1px solid rgb(204, 204, 204);
    background-image: url("images/paper.jpg");
    color: #282c34;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    z-index: 15;
}

.show-session-link .qr-code{
    text-align: center;
    margin: 3vh 0;
}

.close-dialog {
    margin: 1vh;
    padding: 1vh;
    background-color: #5555ff;
    color: #fff;
}
